html {
  --button--background: #5436bd;
  --button--background-hover: #5436bd;
  --button--color: #ffffff;

  --yellow: #ffc800;
  --black: #000000;
  --white: #ffffff;
  --logo--fill: #ffffff;

  --header--height: 12rem;
  --header--background: #121212;
  --header--background-color: #191919;
  --header--font-size: 1.5rem;
  --header__findings-title--width: 24rem;

  --content__extras--width: 12rem;
  --content__confidence-indicator--width: 1.25rem;

  --sidebar--background: #121212;
  --sidebar--header--border-color: #8973ff;
  --sidebar__header--background-hover: #5436bd;

  --sidebar__badge--color: #5436bd;

  --finding--background-color-hover: #5436bd;
  --finding--border-color-hover: #8973ff;

  --confidence-indicator--background: rgba(68, 68, 68, 1);
  --confidence-probability--background: #842d90;

  --dropzone--border-color: #ccc;
  --dropzone--border-width: 0.08rem;
  --dropzone--border-radius: 0.5rem;
  --dropzone__loader--size: 3rem;
  --dropzone__loader--thickness: 0.25rem;
  --dropzone__loader--primary-color: #333333;
  --dropzone__loader--secondary-color: #1a202c;
  --dropzone__trash--color: #ffffff;

  --file-placeholder--color: #ccc;

  --list-screen__uploadzone--height: 24rem;
  --list-screen__uploadzone--width: 48rem;

  --login-screen__body--background: #5436bd;

  --primary-button--background: #5436bd;
  --primary-button--color: #ffffff;

  --light-button--color: #5436bd;
  --light-button--background: #d6cfef;

  --progress-bar--color: #5436bd;
  --progress-bar--color: #5436bd;

  /*prevent bar of white on over-scroll*/
  background-color: var(--black);
}

/* Loading fonts */

@font-face {
  font-family: Inter;
  src: url('../node_modules/@annaliseai/anna-design-tokens/build/fonts/Inter-Thin.ttf') format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: Inter;
  src: url('../node_modules/@annaliseai/anna-design-tokens/build/fonts/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: Inter;
  src: url('../node_modules/@annaliseai/anna-design-tokens/build/fonts/Inter-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: Inter;
  src: url('../node_modules/@annaliseai/anna-design-tokens/build/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: Inter;
  src: url('../node_modules/@annaliseai/anna-design-tokens/build/fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: Inter;
  src: url('../node_modules/@annaliseai/anna-design-tokens/build/fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: Inter;
  src: url('../node_modules/@annaliseai/anna-design-tokens/build/fonts/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: Inter;
  src: url('../node_modules/@annaliseai/anna-design-tokens/build/fonts/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: Inter;
  src: url('../node_modules/@annaliseai/anna-design-tokens/build/fonts/Inter-Black.ttf') format('truetype');
  font-weight: 900;
}

body {
  font-family: Inter;
  overflow-x: hidden;
}
